.header-row {
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.lac-tab-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
  padding: 0 15px;
}

.lac-tab-list {
  padding: 10px;
  box-shadow: 0 20px 40px 5px rgb(0, 0, 0, 0.3);
  border-radius: 15px;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.lac-tab.active {
  color: var(--hms-ui-colors-primary_default);
}

.call-notification-dialog {
  width: 100%;
  max-width: 400px;
}

.call-notification-dialog > span {
  display: none;
}

.call-notification-dialog > h2 > div {
  justify-content: center;
}

.call-notification-dialog .call-action-button {
  flex-direction: column;
  margin: 0 1rem;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  color: #ffffff;
}

.call-notification-dialog .call-action-button.accept {
  background-color: #4caf50;
}

.call-notification-dialog .call-action-button.reject {
  background-color: #f44336;
}

.required::after {
  content: "*";
  position: relative;
  right: -5px;
  color: #ff0000 !important;
}